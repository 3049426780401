
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Watch,
} from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import MediaPlanItem from '@/models/MediaPlanItem'
import DataTable from '@/components/DataTable/index.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import Widget from '@/components/Widget/Widget.vue'
import moment from 'moment'
import { clone } from 'lodash'
import WebMessage from '@/models/WebMessage'
import data_table_fields from './home-fields'

@Component({
  components: {
    Widget,
    DataTable,
    SearchInput,
    IconAction,
  },
})
export default class MediaPlanItemHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  @Ref() readonly searchInput!: SearchInput

  public ready: boolean = false

  public records: number = 0

  public loading: boolean = false

  public query: string[] = []

  public selected: string[] = []

  public fieldFilters: any = {
    schedule_start_at: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    schedule_end_at: moment().add(7, 'day').format('YYYY-MM-DD'),
  }

  public modals: any = {
    batch_push_adserver: false,
  }

  public batch_double_confirm: boolean = false

  public copy_creative_from_previous_line_item: boolean = true

  public ignore_blackouts: boolean = false

  public use_existing_media_plan_order: boolean = true

  public batch_requesting: boolean = false

  @Watch('fieldFilters.schedule_start_at')
  public onScheduleStartAtChange() {
    if (!this.fieldFilters.schedule_start_at || !this.fieldFilters.schedule_end_at) return

    let start = moment(this.fieldFilters.schedule_start_at)
    let end = moment(this.fieldFilters.schedule_end_at)

    if (start.isAfter(end)) {
      this.fieldFilters.schedule_end_at = start.add(7, 'day').format('YYYY-MM-DD')
    }
  }

  @Watch('fieldFilters.schedule_end_at')
  public onScheduleEndAtChange() {
    if (!this.fieldFilters.schedule_start_at || !this.fieldFilters.schedule_end_at) return
    let start = moment(this.fieldFilters.schedule_start_at)
    let end = moment(this.fieldFilters.schedule_end_at)

    if (end.isBefore(start)) {
      this.fieldFilters.schedule_start_at = end.subtract(7, 'day').format('YYYY-MM-DD')
    }
  }

  public fields: any = []

  public busy: boolean = false

  public show_filter_helper: boolean = false

  public filter_mode: string = 'exclusive'

  public query_settings = {
    company_fields: [
      {
        name: 'advertiser',
        key: 'advertiser_id',
        type: 'advertiser',
        description: 'Include only the specified advertiser',
      },
      {
        name: 'agency',
        key: 'agency_id',
        type: 'agency',
        description: 'Include only the specified agency',
      },
    ],
    media_plan_fields: [
      {
        name: 'media_plan',
        key: 'media_plan_id',
        description: 'Include only the specified media plan',
      },
    ],
    custom_fields: [
      {
        name: 'status:ready',
        value: 'status:ready',
        description:
          'Find all upcoming campaigns where status is ready [confirmed, pending documents]',
      },
      {
        name: 'status:draft',
        value: 'status:draft',
      },
      {
        name: 'status:pending documents',
        value: 'status:pending_documents',
      },
      {
        name: 'status:pending review',
        value: 'status:pending_review',
      },
      {
        name: 'status:confirmed',
        value: 'status:confirmed',
      },
      {
        name: 'status:cancelled',
        value: 'status:cancelled',
      },
      {
        name: 'status:lost',
        value: 'status:lost',
      },
      {
        name: 'status:pending revision',
        value: 'status:pending_revision',
      },
    ],
  }

  @Watch('filter_mode')
  public onFilterModeChange() {
    this.refresh()
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public mounted() {
    this.query = this.default_query
    this.fields = data_table_fields
    this.loadFilters()
  }

  public pushAdServer(data: any) {
    this.$router.push({
      name: 'mediaplan-item',
      params: { id: data.id },
      query: { push_adserver: 'upcoming_campagins' },
    })
  }

  public batchPushAdServer(data: any) {
    if (this.selected.length) {
      this.batch_double_confirm = false

      this.modals.batch_push_adserver = true
      /*
      if (this.selected.length === 1) {
        this.$router.push({
          name: 'mediaplan-item',
          params: { id: this.selected[0] },
          query: { push_adserver: 'upcoming_campagins' },
        })
      } else {
        this.modals.batch_push_adserver = true
      }
      */
    }
  }

  public batchPushCreate() {
    this.batch_requesting = true
    MediaPlanItem.batchPushToAdServer({
      media_plan_item_ids: this.selected,
      existing_media_plan_order: this.use_existing_media_plan_order,
      copy_creative_from_prev: this.copy_creative_from_previous_line_item,
      ignore_blackouts: this.ignore_blackouts,
    })
      .then(response => {
        this.batch_requesting = false
        if (response.status === 200) {
          WebMessage.success('Batch Line Item push complete!')
          let items = response.data.result.items
          this.$router.push({
            name: 'line-items',
            query: { pre_load: items },
          })
        }
      })
      .catch(error => {
        this.batch_requesting = false
      })
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()
    // @ts-ignore
    return MediaPlanItem.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy === 'flight_date' ? 'start_at' : context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
      mode: this.filter_mode,
    }).then(({ records, data }) => {
      this.records = records
      this.loading = false
      return data
    })
  }

  public default_query: any = ['status:ready']

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {
      schedule_start_at: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      schedule_end_at: moment().add(7, 'day').format('YYYY-MM-DD'),
    }
    this.query = this.default_query
    this.clearFilters()
    this.refresh()
    this.filter_mode = 'exclusive'
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'media-plan-items',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('media-plan-items').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'media-plan-items',
      data: null,
    })
  }

  public viewChildren(type: string, name: string, id: string) {
    const q = clone(this.query)

    if (q.includes(`${type}:${id}`)) {
      return
    }
    q.push(`${type}:${id}`)
    let type_name = type.replace('_id', '')
    this.searchInput.addCustomTag({
      name: `${type_name}:${name}`,
      value: `${type}:${id}`,
    })
    this.query = q
  }
}
