export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    type: 'checkbox',
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'upcoming_name',
    label: 'Name',
    sortable: true,
    class: 'text-left align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'agency',
    label: 'Agency',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'station',
    label: 'Station',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'advertiser',
    label: 'Advertiser',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },

  {
    key: 'account_manager',
    label: 'Account Manager (AM)',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'schedule_start_at',
    label: 'Start Date',
    sortable: true,
    filter_type: 'date',
    type: 'date',
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'schedule_end_at',
    label: 'End Date',
    sortable: true,
    filter_type: 'date',
    type: 'date',
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'impressions',
    label: 'Impressions',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'ad_server',
    label: 'Has Ad Server Line Item',
    sortable: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: '',
    sortable: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    filter: false,
    thStyle: { width: '160px' },
  },
]
